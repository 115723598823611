.container {
  padding: 0px 15px !important;
}

.navbar {
  padding: 5px 16px 5px 16px;
  border-bottom: 1px solid #e7e7e7;
  margin-bottom: 20px;
}

.navbar-brand {
    color: grey !important;
    font-size: 24px;
    height: 40px;
    margin-top: -11px !important;
    vertical-align: middle !important;
}

.resume h3 {
  color: grey;
  border-bottom: 1px solid lightgrey;
  margin-top: 20px;
  font-weight: 400;
}

.resume .company {
  font-weight: bold;
  font-size: 20px;
}

.resume .location {
}

.resume .dates {
  float: right;
  font-style: italic;
  font-size: 14px;
}

.resume .title {
  margin-top: -5px;
  margin-bottom: 10px;
  font-style: italic;
  color: grey;
}

.resume .team {
  font-weight: bold;
}

.resume .stack {
  margin-top: -3px;
  margin-bottom: 0px;
  color: grey;
  font-style: italic;
}

.resume .project {
  font-weight: bold;
}

.resume p {
  font-size: 14px;
}

.resume ul {
  padding-left: 20px;
}

.resume .skill {
  font-weight: bold;
}

